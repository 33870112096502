import type { BlogArticle, BlogArticleSummary, BlogFilters } from "@/modules/corporate/types/blog"
import type { PagePagination } from "@/modules/corporate/types/pagePagination"
import type { Page } from "@/modules/corporate/types/pages"
import type { BlogSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import dynamic from "next/dynamic"

export type BlogPropsType = {
  currentPersonaRootPage: Page | null | undefined
  page: Page
  pagePagination: PagePagination<BlogArticleSummary, BlogFilters> | null
  section: BlogSection
  subPage: BlogArticle | null
}

const DynamicBlogIndex = dynamic(() => import("./BlogIndex").then(mod => mod.BlogIndex))
const DynamicBlogArticle = dynamic(() => import("./BlogArticle").then(mod => mod.BlogArticleComponent))

export const Blog: FunctionComponent<BlogPropsType> = ({
  currentPersonaRootPage,
  page,
  pagePagination,
  section,
  subPage,
}) => (
  <div>
    {subPage ? (
      <DynamicBlogArticle
        article={subPage}
        currentPersonaRootPage={currentPersonaRootPage}
        page={page}
        section={section}
        pagePagination={pagePagination}
      />
    ) : (
      <DynamicBlogIndex pagePagination={pagePagination} page={page} section={section} />
    )}
  </div>
)
